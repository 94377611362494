import {MIN_CHUNK_BUFFER} from "../constants";

export default class ChunkPairsBuffer {
    constructor(optInGracePeriodMsec) {
        this._bufferMaxFrames = 10 * 60 * 100; // In case there is no data on the length of audio chunks
        this._optInGracePeriodMsec = optInGracePeriodMsec;
        this._data = [];
    }

    setOptInGracePeriodMsec(optInGracePeriodMsec){
        this._optInGracePeriodMsec = optInGracePeriodMsec < MIN_CHUNK_BUFFER ? MIN_CHUNK_BUFFER : optInGracePeriodMsec;
    }

    getOptInGracePeriodMsec(){
        return this._optInGracePeriodMsec;
    }

    enqueue(chunk) {
        this._data.push(chunk);

        const dataLen = this._data.length;
        const lasrChunkOffsetMsec = this._data[dataLen - 1]?.offsetInAudioStreamMsec || 0;

        if(dataLen > 0 && this._optInGracePeriodMsec > 0){
            let i = -1;
            for(let idx = dataLen - 1; idx >= 0; idx--){
                const audioOverLimit = (lasrChunkOffsetMsec - this._data[idx].offsetInAudioStreamMsec);
                if(audioOverLimit > this._optInGracePeriodMsec){
                    i = idx;
                    break;
                }
            }
            if(i > 0){
                this._data = this._data.slice(i);
            }
        }

        if (this._data.length >= this._bufferMaxFrames) {
            this.dequeue();
        }
    }

    dequeue() {
        return this._data.shift();
    }

    size() {
        return this._data.length;
    }

    countOfSend() {
        let counter = 0;
        this._data.forEach(chunk => {
            if (chunk.send) {
                counter++;
            }
        });

        return counter;
    }

    mergeToOne() {
        let totalLength = 0;
        let offsetInAudioStreamMsec = 0;
        const dataLen = this._data.length;

        if (dataLen === 0) {
            return;
        }

        for (let index = dataLen - 1; index >= 0; index--) {
            const chunk = this._data[index];
            if (chunk.send) {
                break;
            }
            offsetInAudioStreamMsec = chunk.offsetInAudioStreamMsec;
            totalLength += chunk.audioChunk.length;
        }

        const combinedAudio = new Uint8Array(totalLength);
        let offset = totalLength;

        for (let index = dataLen - 1; index >= 0; index--) {
            const chunk = this._data[index];
            if (chunk.send) {
                break;
            }
            offset -= chunk.audioChunk.length;
            combinedAudio.set(chunk.audioChunk, offset);

            // TODO: mark item as send only after it was dispatched to socket
            // eslint-disable-next-line no-param-reassign
            chunk.send = true;
        }

        return {
            offsetInAudioStreamMsec,
            audioChunk: combinedAudio,
            channel: this._data[0].channel,
        };
    }

    markAllUnSend() {
        this._data.forEach(chunk => {
            // eslint-disable-next-line no-param-reassign
            chunk.send = false;
        });
    }

    reset() {
        this._data = []
    }

    getDurationMsec(){
        const buffLast = this._data[this._data.length -1];
        const buffFirst = this._data[0];

        return buffLast.offsetInAudioStreamMsec - buffFirst.offsetInAudioStreamMsec;
    }

    getTimestampOffsetMsec(){
        let offsetInAudioStreamMsec = 0;
        const dataLen = this._data.length;
        for (let index = dataLen - 1; index >= 0; index--) {
            const chunk = this._data[index];
            if (chunk.send) {
                break;
            }
            offsetInAudioStreamMsec = chunk.offsetInAudioStreamMsec;
        }

        return offsetInAudioStreamMsec;
    }
}
