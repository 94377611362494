import styled from 'styled-components';
import { TopicWrapper } from '../../WordsList/KeyphraseStack/styled';
import CardRow from './CardRow';
import { colors } from '../../../../utils';

const Card = styled('div')`
    width: 100%;

    border-radius: 4px;

    background-color: ${colors.white.basic};
    //box-shadow: 0 2px 6px 0 #cdd3df;
    overflow: hidden;
    
    min-height: 125px;
    padding: 10px;
    
    background-color: ${props =>
        !props.$isEnabled && !props.$isBlacklist
            ? 'rgba(255, 255, 255, 0.5)'
            : 'rgba( 255,255, 255, 1)'};
    & > ${CardRow} {
        //opacity: ${props => (!props.$isEnabled && !props.$isBlacklist ? 0.5 : 1)};
    }
    & ${TopicWrapper} {
         opacity: ${props => (!props.$isEnabled && !props.$isBlacklist ? 0.5 : 1)};
    }

    opacity: ${props => (!props.$isEnabled && props.$isBlacklist ? 0.5 : 1)};
    //overflow-y: auto;
    position: relative;
    //outline-style: none;

    // &::-webkit-scrollbar {
    //     width: 4px;
    // }
    //
    // &::-webkit-scrollbar-thumb {
    //     background: ${colors.gray.iron};
    //     border-radius: 2px;
    // }
    //
    // &::-webkit-scrollbar-track {
    //     background: transparent;
    //     border-radius: 2px;
    // }
`;

export default Card;
