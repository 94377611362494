import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import styled from 'styled-components';
import { colors } from '../../../utils';

const Wrapper = styled.div`
    padding: 0 2px 0 10px;
`;

export function ScrollableContainer({ children }) {
    return (
        <Scrollbar
            removeTracksWhenNotUsed
            trackYProps={{
                style: {
                    width: '4px',
                    right: '2px',
                    background: 'none'
                }
            }}
            thumbYProps={{
                style: {
                    background: colors.gray.iron
                }
            }}
            scrollerProps={{
                renderer: props => {
                    // eslint-disable-next-line react/prop-types
                    const { elementRef, ...restProps } = props;
                    return <div {...restProps} ref={elementRef} data-scroller="true" />;
                }
            }}>
            <Wrapper>{children}</Wrapper>
        </Scrollbar>
    );
}

ScrollableContainer.propTypes = {
    children: PropTypes.any
};

ScrollableContainer.defaultProps = {
    children: PropTypes.any
};
