import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import Popup from 'reactjs-popup';
import {
    StartPhraseKeywords,
    StartPhraseKeywordsPopup
} from './styled/StartPhraseKeywords';
import { CountPill, Icon, WordsList } from '..';
import { Card, CardRow, List, Title, ExpandWrapper, ExpandIcon } from './styled';
import EmptyState from './EmptyState';

class WordsDetection extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        words: PropTypes.array,
        localization: PropTypes.object.isRequired,
        defaultWordsRendered: PropTypes.number,
        configLoaded: PropTypes.bool.isRequired,
        isEnabled: PropTypes.bool.isRequired,
        moveSaidDoSayWordsToBottom: PropTypes.bool,
        isButtonShown: PropTypes.bool,
        displayDoSayAlternatives: PropTypes.bool,
        displayStartStopKeywords: PropTypes.bool,
        isCallStarted: PropTypes.bool,
        keyPhraseIntegrationWords: PropTypes.object
    };

    static defaultProps = {
        words: [],
        defaultWordsRendered: null,
        displayDoSayAlternatives: true,
        isButtonShown: false,
        moveSaidDoSayWordsToBottom: false,
        displayStartStopKeywords: false,
        isCallStarted: false,
        keyPhraseIntegrationWords: null
    };

    state = {
        isClicked: false,
        wordsRendered: !this.props.defaultWordsRendered
            ? this.props.words.length
            : this.props.defaultWordsRendered
    };

    componentDidUpdate(prevProps) {
        if (prevProps.words.length !== this.props.words.length) {
            this.setState({
                wordsRendered: !this.props.defaultWordsRendered
                    ? this.props.words.length
                    : this.props.defaultWordsRendered
            });
        }
    }

    _getDetectionCount(words) {
        const { type } = this.props;
        if (!words) return;

        if (type === 'blacklist') {
            return words.filter(word => word.count !== 0).length;
        }

        // Remove word not detected
        return words.filter(word => sumBy(word.alternatives, 'count') !== 0).length;
    }

    _renderList() {
        const {
            localization,
            type,
            words,
            configLoaded,
            moveSaidDoSayWordsToBottom,
            displayDoSayAlternatives,
            isButtonShown
        } = this.props;
        const { wordsRendered } = this.state;

        if (type === 'blacklist' && !this._getDetectionCount(words)) {
            return <EmptyState localization={localization} />;
        }

        return (
            <List>
                <WordsList
                    displayDoSayAlternatives={displayDoSayAlternatives}
                    configLoaded={configLoaded}
                    words={words}
                    wordsRendered={wordsRendered}
                    type={type}
                    moveSaidDoSayWordsToBottom={moveSaidDoSayWordsToBottom}
                    maxHeight={isButtonShown ? 238 : 280}
                />
            </List>
        );
    }

    _toggleMore(event) {
        const { defaultWordsRendered, words } = this.props;

        event.preventDefault();

        this.setState(prevState => ({
            isClicked: !prevState.isClicked,
            wordsRendered:
                prevState.wordsRendered === defaultWordsRendered
                    ? words.length
                    : defaultWordsRendered
        }));
    }

    _renderExpandButton() {
        const { localization, defaultWordsRendered, words } = this.props;
        const { isClicked } = this.state;
        if (!defaultWordsRendered || words.length <= defaultWordsRendered) return;
        return (
            <ExpandWrapper onClick={event => this._toggleMore(event)}>
                {localization.getText(
                    `trainer.expand_button.${isClicked ? 'hide' : 'more'}`
                )}
                <ExpandIcon $clicked={isClicked}>
                    <Icon name="Triangle" />
                </ExpandIcon>
            </ExpandWrapper>
        );
    }

    _renderDetectionCount() {
        const { type, words } = this.props;

        if (type === 'blacklist') return null;

        return <CountPill count={this._getDetectionCount(words)} type={type} />;
    }

    _renderStartStopKeywordsPopup() {
        if (
            !this.props.displayStartStopKeywords ||
            !this.props.keyPhraseIntegrationWords
        ) {
            return null;
        }

        const { start, stop } = this.props.keyPhraseIntegrationWords;

        if (this.props.isCallStarted) {
            if (!start || start.length === 0) {
                return null;
            }
        } else if (!stop || stop.length === 0) {
            return null;
        }

        const StartPhraseIcon = (
            <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                    width: '16px',
                    height: '16px'
                }}>
                <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
            </svg>
        );

        const StopPhraseIcon = (
            <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                    width: '14px',
                    height: '14px'
                }}>
                <path d="M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29L.29 13.08c-.18-.17-.29-.42-.29-.7 0-.28.11-.53.29-.71C3.34 8.78 7.46 7 12 7s8.66 1.78 11.71 4.67c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28-.79-.74-1.69-1.36-2.67-1.85-.33-.16-.56-.5-.56-.9v-3.1C15.15 9.25 13.6 9 12 9z" />
            </svg>
        );

        return (
            <div>
                <Popup
                    position="bottom left"
                    // arrow
                    // arrowStyle={{
                    //     color: colors.blue.hawkes_blue
                    // }}
                    offsetY={-5}
                    offsetX={-30}
                    on="hover"
                    trigger={
                        <StartPhraseKeywords $isCallStarted={this.props.isCallStarted}>
                            {this.props.isCallStarted ? StopPhraseIcon : StartPhraseIcon}
                        </StartPhraseKeywords>
                    }>
                    <StartPhraseKeywordsPopup>
                        <b>
                            {this.props.isCallStarted
                                ? this.props.localization.getText(
                                      'trainer.call_messages.key_phrase_integration_stop_words'
                                  )
                                : this.props.localization.getText(
                                      'trainer.call_messages.key_phrase_integration_start_words'
                                  )}
                        </b>
                        <div>
                            {(this.props.isCallStarted ? stop : start).map(t => {
                                return <span key={t}>{t}</span>;
                            })}
                        </div>
                    </StartPhraseKeywordsPopup>
                </Popup>
            </div>
        );
    }

    render() {
        const { type, localization, isEnabled } = this.props;

        return (
            <Card $isEnabled={isEnabled} $isBlacklist={type === 'blacklist'}>
                <CardRow>
                    <Title>
                        {localization.getText(`trainer.${type}.title`)}{' '}
                        {this._renderStartStopKeywordsPopup()}
                    </Title>
                    {this._renderDetectionCount()}
                </CardRow>

                {this._renderList()}
                {this._renderExpandButton()}
            </Card>
        );
    }
}

export default WordsDetection;
