import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import Scrollbar from 'react-scrollbars-custom';
import { WordLabel } from '..';
import { Wrapper, ListPlaceholder } from './styled';
import KeyphraseStack from './KeyphraseStack';
import { colors, words as wordsUtil } from '../../../utils';

const { getPillType, getSortedDetection } = wordsUtil;

class WordsList extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        words: PropTypes.array,
        wordsRendered: PropTypes.number,
        configLoaded: PropTypes.bool.isRequired,
        moveSaidDoSayWordsToBottom: PropTypes.bool,
        displayDoSayAlternatives: PropTypes.bool,
        maxHeight: PropTypes.number
    };

    static defaultProps = {
        words: [],
        wordsRendered: null,
        moveSaidDoSayWordsToBottom: false,
        displayDoSayAlternatives: true,
        maxHeight: 0
    };

    state = {
        maxHeight: this.props.maxHeight
    };

    _renderContent() {
        const {
            type,
            words,
            wordsRendered,
            configLoaded,
            moveSaidDoSayWordsToBottom,
            displayDoSayAlternatives
        } = this.props;

        if (!configLoaded) {
            return <ListPlaceholder />;
        }

        if (words.length === 0) {
            return null;
        }

        if (type === 'whitelist') {
            const sortedWords = moveSaidDoSayWordsToBottom
                ? getSortedDetection(words, false, 'asc')
                : words;

            return sortedWords.map(phraseGroup => {
                return (
                    <KeyphraseStack
                        displayDoSayAlternatives={displayDoSayAlternatives}
                        topic={phraseGroup.text}
                        key={phraseGroup.text}
                        alternatives={phraseGroup.alternatives}
                        complements={phraseGroup.complements}
                    />
                );
            });
        }

        return words.map((word, key) => {
            if (wordsRendered && key >= wordsRendered) return null;
            if (!word.count) return null;

            const localCount = sumBy(word.alternatives, 'count');

            return (
                <WordLabel
                    key={word.text}
                    pillType={getPillType(type, word.count)}
                    isClickable={false}
                    count={localCount}>
                    {word.text}
                </WordLabel>
            );
        });
    }

    checkHeight(ref) {
        if (ref) {
            const maxH = this.props.maxHeight;
            const wordListHeight = ref.getBoundingClientRect().height;
            const newH = wordListHeight < maxH ? wordListHeight : maxH;

            if (this.state.maxHeight !== newH) {
                this.setState({
                    maxHeight: newH
                });
            }
        }
    }

    render() {
        if (this.props.maxHeight === 0) {
            return <Wrapper>{this._renderContent()}</Wrapper>;
        }

        return (
            <Scrollbar
                removeTracksWhenNotUsed
                trackYProps={{
                    style: {
                        width: '4px',
                        right: '2px',
                        background: 'none'
                    }
                }}
                thumbYProps={{
                    style: {
                        background: colors.gray.iron
                    }
                }}
                style={{ height: this.state.maxHeight }}
                scrollerProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return (
                            <div {...restProps} ref={elementRef} data-scroller="true" />
                        );
                    }
                }}>
                <Wrapper ref={ref => this.checkHeight(ref)}>
                    {this._renderContent()}
                </Wrapper>
            </Scrollbar>
        );
    }
}
export const PureWordsList = WordsList;
export default WordsList;
