import axios from "axios";
import sortBy from 'lodash/sortBy'
import {captureException} from "../../../tracker/raven";
import {BASE_URL} from "../../../utils/config";

export const getSalesTrackingData = async ({authToken}) => {
    return axios.get(`${BASE_URL}/kpi/v1/projects`, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    }).then(({data}) => {
        const listOfProjects = Array.isArray(data) ? data : [];
        return sortBy(listOfProjects.reduce((prevValue, project) => {
            const products = [];
            const categories = (project?.products || {});
            Object.keys(categories).forEach((category) => {
                const productsOfCategory = categories[category];
                productsOfCategory.forEach(({name}) => {
                    products.push({
                        product: name,
                        category,
                        campaign: project.project,
                    })
                })
            })

            return prevValue.concat(products);
        }, []), ['campaign', 'category', 'product']);
    }).catch(e => {
        captureException(e)
        return [];
    })
}
